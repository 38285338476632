import React from 'react';

import {
    ArtworkContentContainer,
    ArtworkData,
} from './styledElements';

import Header from './Header';
import Image from './Image/Image';
import Info from './Info';

const ArtworkContent = ({
    artwork,
    assetsPath,
    cdnPath,
    contactEmail,
    documentAssetsPath,
    entryAssetsPath,
    language,
    listSettings,
    showPrice,
    exhibition,
    disableArrowNav,
    backPath,
}) => {
    const {
        details_file_name,
        image_file_name,
    } = artwork;
    const {
        artworkIndex,
        artworksTotal,
        nextArtworkSlug,
        previousArtworkSlug,
    } = listSettings;
    const imageUrls = image_file_name.split(';');
    const imageSources = imageUrls.map((url) => `${cdnPath}/artworks/${url}`);
    const documentSource = `${documentAssetsPath}/${details_file_name}`;
    const firstImagePath = imageSources.length ? imageSources[0] : null;

    return (
        <ArtworkContentContainer>
            <Header
                artworkIndex={artworkIndex}
                artworksTotal={artworksTotal}
                entryAssetsPath={entryAssetsPath}
                language={language}
                nextArtworkSlug={nextArtworkSlug}
                previousArtworkSlug={previousArtworkSlug}
                backPath={backPath}
            />
            <ArtworkData>
                <Image imageSources={imageSources}/>
                <Info
                    artwork={artwork}
                    exhibition={exhibition}
                    contactEmail={contactEmail}
                    documentSource={documentSource}
                    showPrice={showPrice}
                    disableArrowNav={disableArrowNav}
                    firstImagePath={firstImagePath}
                />
            </ArtworkData>
        </ArtworkContentContainer>
    )
};

export default ArtworkContent;
